module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"type":{"Category":{"exclude":true},"Comment":{"exclude":true},"ContentType":{"exclude":true},"GfSubmittedEntry":{"exclude":true},"GfEntry":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"PostFormat":{"exclude":true},"Tag":{"exclude":true},"Taxonomy":{"exclude":true},"UserRole":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"perPage":50,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"url":"https://wp.compliantrx.ai/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CompliantRx","short_name":"CompliantRx","start_url":"/","background_color":"#F0F1F9","theme_color":"#43247D","icon":"content/assets/CompliantRx-Favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"98172e246b92065653bec7dfba3f4dd0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
